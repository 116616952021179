@import "rsuite/dist/rsuite.css";

.meterdatamain {
    min-height: calc(100vh - 40px);
}

.machinetabledata {
    margin: 1rem;
    font-size: 0.8rem;
}

.gatherdateID {
    text-align: center;
    margin-bottom: 1rem;
    /* margin-left: -7rem; */
}

.timeID {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.time {
    margin-right: 2rem;
    margin-left: -1rem;
}

.rangepiker {
    width: 14rem;
}

.guageChartcontainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.btnreport {
    background-color: transparent;
    border: 1px solid #3e4492;
    padding: 2px 10px;
    border-radius: 5px;
    margin: 0 0.5rem;
    width: 4rem;
    margin: 0.3rem 0rem;
    align-self: center;
    font-size: 0.7rem;
    margin-left: 2rem;

}

.btnreport:hover {
    border: 1px solid #3e4492;
    color: white;
    background-color: #3e4492;
}

.datatable .datah {
    /* border: 1px solid #3e4492; */
    color: white;
    padding: 0.5rem;
    font-weight: bold;
    background-color: #3e4492;
}

.datatable .datad {
    border: 1px solid #3e4492;
    text-align: center;
    border-collapse: collapse;

}

.datatable .dataupdate {
    height: 1.5rem;
}

.datatable .datastatic {
    background-color: #3e4492;
    color: white;
}

.datatable .datareport {
    display: flex;
    flex-direction: column;
    /* width: 12rem; */
}

.datatable .tempid {
    font-size: 0.7rem;
}

.sensordata {
    display: flex;
}

.sensordata .machinesensormain {
    padding: 0.5rem;
    border: 1px solid gray;
    margin: 1rem;
    border-radius: 10px;
    background-color: rgb(231, 228, 228);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 10rem;
}

.sensordata .machinedataheading {
    font-size: var(--headingSize);
}

.sensordata .machinedatapara {
    padding: 0.5rem;
}

.headingvalue {
    font-size: 0.9rem;
}

.linechartcontainer {
    width: 70%;
    height: 50vh;
    align-self: flex-start;
    /* margin: auto; */
}

.getherCharts {
    display: flex;
}

@media screen and (max-width:800px) {
    .getherCharts {
        flex-direction: column;
    }
    .guageChartcontainer{
        flex-direction: row;
    }
    .linechartcontainer{
        width: 100%;
        height: 40vh;
        margin-bottom: 2rem;
    }
    .guage{
        margin: 0rem;
        width: 10rem;
    }
}