.loginuserimage{
    height: 5rem;
    width: 5rem;
}
.loginmainContainer{
    color: white;
    height: 100vh;
    width: 100%;
    background: linear-gradient(var(--backDegree) ,var(--back1Color),var(--back2Color),var(--back3Color));
    background-size: 180% 180%;
    animation: gradient-animation 15s ease-in-out infinite;
  }
  
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}

.logincontainer{
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    height: 60vh;
    justify-content: center;
    align-items: center;
}
.logincontainer .loginform{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-start;
    /* background-color: rgb(184, 218, 215); */
    height: 15rem;
    width: 20rem;
    border-radius: 10px;
    padding: 2rem;
}
.logincontainer .headinglogin{
    font-size: 2rem;
    padding-bottom: 1rem;
}

.logincontainer .logininput{
    padding: 0.4rem;
    /* border-radius: 5px; */
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    border-bottom: 1px solid gray;
    /* color: white; */
    cursor: text;
    margin-bottom: 1rem;
    width: 15rem;
}

.logincontainer .btnlogin{
    margin-top: 5px;
    align-self: flex-end;
    background-color: transparent;
    border: 1px solid var(--backColor);
    padding: 2px 7px;
    width: 4rem;
    border-radius: 5px;
    font-size: 0.7rem;
    margin-right: 1rem;


}
.logincontainer .btnlogin:hover{
    background-color: var(--backColor);
    color: white;
}